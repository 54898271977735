import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"



const BrandPage = () => {


    return (
        <Layout>
            <Seo title="Brand Demo" />

            <div className="blog-wrapper">


                <div className="blog-post">

                    <h1>It's not my fault  Giving errors a voice</h1>
                    <p>Alt tags are the easiest attributes to miss off an image; they’re also super easy for automated tests to find and flag up. This post aims to dispel some untruths about the humble alt text. </p>

                    <p>With the saturation of IDEs and the addition of helpful code-completes and guidance, alternative text has become the poster child of accessibility validation. (It’s also the first item on the WCAG list - so it’s gotta be pretty important) </p>

                    <p>T-bone chuck ham hock alcatra burgdoggen drumstick pork chop bresaola. Pork chop ball tip sirloin pig landjaeger. Beef prosciutto pancetta, andouille short ribs filet mignon chicken. Beef ribs drumstick tri-tip ribeye burgdoggen, bresaola flank t-bone venison chicken andouille.</p>
                    <h2>Why Netflix shares are down 10%</h2>
                    <p>Resizing just the text by 200%: A user should be able to resize the text by 200%. It should be functional and readable. Note that a user must be able to use assistive technology to increase letter*, word, line and paragraph spacing without loss of content or functionality (often required by dyslexic users).</p>
                    <p>Zooming the browser 400% (Reflow) - A user should be able to scale the browser to 400%. The zoom feature, in modern browsers, scales the whole page and triggers responsive breakpoints and reflow; this allows a user to get to mobile phone resolution on a desktop - ideal for people with low vision. Note: Content that is zoomed shouldn’t require the user to scroll horizontal... the information should automatically reflow into a single column.</p>
                    <h3>Why Netflix shares are down 10%</h3>
                    <p>So why do we need both?  Many users who have low vision don’t just use a browser; they have to set their font size at the operating system level... so their text is already bigger.
                    </p>
                    <h4>Why Netflix shares are down 10%</h4>
                    <p>So why do we need both?  Many users who have low vision don’t just use a browser; they have to set their font size at the operating system level... so their text is already bigger.
                    </p>
                    <h5>Here is a smaller header</h5>
                    <p>So why do we need both?  Many users who have low vision don’t just use a browser; they have to set their font size at the operating system level... so their text is already bigger.
                    </p>
                    <p className="small">UX | VOLVO</p>

                </div>
                <div className="blog-author">
                    author block
                </div>

                <div className="blog-aside"> blog aside-block</div>

            </div>


        </Layout>
    );

};



export default BrandPage;
